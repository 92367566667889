.add__btn {
  padding: 4px 8px;
  border: 1px solid transparent;
  border-radius: 5px;
  color: #fff;
  background: #df2020;
}

.single__product-content {
  padding-left: 50px;
}

.product__price {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 0;
}

.product__price span {
  font-size: 1.5rem;
}

.product__category {
  font-weight: 600;
  color: #212245;
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.tabs {
  border-bottom: 1px solid #fde4e4;
}

.tabs h6 {
  cursor: pointer;
}

.tab__content {
  padding: 30px 0px;
}

.tab__content p {
  line-height: 30px;
}

.tab__active {
  color: red;
}

.form {
  width: 60%;
  margin: auto;
  background: #fde4e4;
  padding: 20px;
  border-radius: 5px;
}

.form__group {
  margin-bottom: 30px;
}

.form__group input,
.form__group textarea {
  width: 100%;
  background: transparent;
  padding: 7px 10px;
  border: none;
  outline: none;
  border-bottom: 1px solid #2128455d;
}

.user__name {
  font-weight: 600;
  color: #212245;
}

.user__email {
  font-size: 0.8rem;
}

.feedback__text {
  color: #212245b6;
}

.img__item {
  display: flex;
  justify-content: center;
  align-items: center;
}



.img__item img {
  cursor: pointer;
}

.related__products-title {
  font-size: 1.4rem;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .product__title {
    font-size: 1.4rem;
  }

  .product__price {
    font-size: 1rem;
  }

  .product__category {
    font-size: 0.9rem;
  }

  .add__btn {
    padding: 2px 6px;
    font-size: 1.1rem;
  }

  .tabs h6 {
    font-size: 0.9rem;
  }

  .tab__content p {
    font-size: 0.92rem;
    line-height: 25px;
  }

  .user__name {
    margin-top: 10px;
    font-size: 0.92rem;
  }
  .user__email {
    font-size: 0.72rem;
    margin-bottom: 10px;
  }

  .feedback__text {
    font-size: 0.9rem;
    margin-bottom: 11px;
  }

  .form {
    width: 100%;
    font-size: 0.9rem;
  }
  .img__item img {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product__title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product__price {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product__category {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add__btn {
    display: flex;
    margin: 30px auto;
  }

  .single__product-content {
    padding-left: 0;
  }
  .product__main-img {
    padding: 0;
  }
}

@media only screen and (max-width: 575px) {
  .product__images {
    display: flex;
    flex-direction: row;
  }

  .product__main-img {
    margin-bottom: 20px;
  }
  .product__main-img {
   width: 50%;
   display: flex;
   margin: auto;
  }

  .product__title{
    font-size: 1.1rem;
  }

  .product__price{
    font-size: .9rem;
  }

  .product__price span{
    font-size: 1.1rem;
    
  }

  .tabs h6{
    font-size: .75rem;
  }

  .tab__content p{
    font-size: .85rem;
    line-height: 23px;
  }

  .feedback__text{
    font-size: .85rem;
    line-height: 21px;
  }
}
