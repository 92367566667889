.common__section {
  padding: 80px 0;

  background: linear-gradient(#2122454f, #2122453c),
    url("http://wallpapers.net/restaurant-dining-tables-hd-wallpaper/download/5120x2160.jpg");
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
.common__section h2 {
  text-align: center;
  letter-spacing: 0.2rem;
  color: red;
}

@media only screen and (max-width: 300px) {
  .common__section h2 {
    font-size: 1.5rem;
  }
}
