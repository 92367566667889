
.cart__item-info img{
    width: 35px;
    height: 35px;
    object-fit: cover;
}

.cart__product-title{
    font-size: .8rem;
    font-weight: 600;
}

.cart__product-price{
    font-size: .8rem;

}

.cart__product-price span{
    font-size: .9rem;
    font-weight: 600;
    color: #df2020;
}

.increase__decrease-btn{
    background: #fde4e4;
    width: 100px;
    padding: 2px 5px;
    border-radius: 5px;
}

.increase__btn,
.decrease__btn{
    cursor: pointer;
}

.delete__btn{
    cursor: pointer;
    font-size: 1.2rem;
}
.cart__item{
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(168, 167, 167, 0.347) !important;
}