.cart__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000008b;
  z-index: 9999;
}

.cart {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background: #fff;
  z-index: 99999;
}

.cart__close {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.cart__close span i {
  font-size: 1.2rem;
  cursor: pointer;
  padding: 5px;
  background: #df2020;
  border-radius: 50%;
  color: #fff;
}

.cart__item-list {
  height: calc(100vh - 120px);
  overflow-y: scroll;
}

.cart__bottom{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.cart__bottom h6 {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.cart__bottom span {
  color: #df2020;
}

.cart__bottom button {
  padding: 4px 10px;
  border: 1px solid transparent;
  border-radius: 10px;
  background: #df2020;
  color: #fff;
  transition: 0.3s;
}

.cart__bottom button:hover a {
  color: #fff;
}

.cart__bottom button:hover {
  background: #212245;
}
